import PortfolioContainer from './PortfolioContainer/PortfolioContainer';
import './App.css';

const App = () => {
  return(
    <div className="App">
      <PortfolioContainer />
    </div>
  )
}

export default App;